import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { exportTableToExcel } from './utils/excelExport.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/app.scss";
import '@vuepic/vue-datepicker/dist/main.css';
import 'v-calendar/dist/style.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "vue3-toastify/dist/index.css";

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import Datepicker from '@vuepic/vue-datepicker';
import VueFeather from "vue-feather";
import Breadcrumbs from './layout/breadCrumbs.vue';
import VCalendar from 'v-calendar';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from "vue3-apexcharts";

import { createI18n } from 'vue-i18n';
import English from "./locales/en.json";
import Español from "./locales/es.json";
import Deutsch from "./locales/de.json";
import Français from "./locales/fr.json";
import Português from "./locales/pt.json";
import 简体中文 from "./locales/cn.json";
import لعربية from "./locales/ae.json";
import German from "./locales/ge.json";
import Russian from "./locales/ru.json";
import Arabic from "./locales/ar.json";

const i18n = createI18n({ legacy: false, // you must specify 'legacy: false' option
  locale: 'ja',
  messages: {
   English: English,
    Español: Español,
    Deutsch: Deutsch,
    Français: Français,   
   Português: Português,
    简体中文: 简体中文,
    لعربية: لعربية,
    German: German,
    Russian: Russian,
    Arabic: Arabic
    }
  })

const app = createApp(App);

// Configuración de Vuetify 3
const vuetify = createVuetify({
  components,
  directives,
});

app.config.globalProperties.$exportTableToExcel = exportTableToExcel;

app.use(store)
   .use(router)
   .use(VueSweetalert2)
   .use(VueApexCharts)
   .use(vuetify)
   .use(VCalendar, {})
   .use(i18n)
   .component(VueFeather.name, VueFeather)
   .component('Breadcrumbs', Breadcrumbs)
   .component('Datepicker', Datepicker)
   .mount('#app');
