<template>
  <div class="error-wrapper">
    <div class="container">
      <div class="svg-wrraper">
        <errorPage1 />
      </div>
      <div class="col-md-8 offset-md-2">
        <h3>¡Acceso denegado!</h3>
        <p class="sub-content">
          No tienes los permisos necesarios para acceder a esta página. Por favor, verifica tus credenciales o contacta
          al administrador para obtener acceso.
        </p>
        <router-link class="btn btn-primary" to="/"> Ir a la página principal </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import errorPage1 from "@/svg/errorPage1.vue";
export default {
  components: {
    errorPage1,
  },
};
</script>
