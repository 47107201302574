<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <h3>Reporte de productos Superkompras va</h3>
        <br>
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>CON FOTOGRAFIA:</label>
            <select class="form-select digits" v-model="filtrado">
              <option value="TODAS">Ambas</option>
              <option value="SI">SI</option>
              <option value="NO">NO</option>
            </select>
          </div>
          <div class="col-lg-4 col-12 mb-3">
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <button @click="aplicarfiltros" class="btn btn-primary mb-3" style="margin-right: 10px;" :disabled="isLoading">Consultar 
              <i class="icofont icofont-search"></i>
            </button>
            <button @click="descargarInforme" class="btn btn-primary mb-3">Descargar Informe
              <i class="icofont icofont-download ms-2"></i>
            </button>
          </div>
        </div>
      </div>
      <Loader v-if="isLoading" :textoLoader="textoLoader" :tituloLoader="tituloLoader" />
      <div class="card-body">
        <div class="table-responsive theme-scrollbar">
          <table class="display table-striped table-hover table-bordered table">
            <thead>
              <tr>
                <th>Código de Barras</th>
                <th>DESCRIPCION</th>
                <th>NPROV</th>
                <th>RFC</th>
                <th>SCATEGORIA</th>
                <th>CATEGORIA</th>
                <th>SBCATEGORIA</th>
                <th>GRUPO</th>
                <th>FOTO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="producto in get_rows" :key="producto.cb">
                <td>{{ producto.cb }}</td>
                <td>{{ producto.descripcion }}</td>
                <td>{{ producto.nprov }}</td>
                <td>{{ producto.rfc }}</td>
                <td>{{ producto.scategoria }}</td>
                <td>{{ producto.categoria }}</td>
                <td>{{ producto.sbcategoria }}</td>
                <td>{{ producto.grupo }}</td>
                <td>{{ producto.FOTO }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul class="pagination pagination-primary p-2 d-flex justify-content-end mt-3" v-if="num_pages() > 0">
          <li class="page-item" v-if="currentPage != 1">
            <a class="page-link" @click="change_page(1)">&laquo;</a>
          </li>
          <li class="page-item" v-if="currentPage != 1">
            <a class="page-link" @click="change_page(currentPage - 1)">Anterior</a>
          </li>
          <li v-if="(currentPage - 1) > 0" class="page-item" @click="change_page(currentPage - 1)">
            <a class="page-link">{{ currentPage - 1 }}</a>
          </li>
          <li class="page-item active">
            <a class="page-link">{{ currentPage }}</a>
          </li>
          <li v-if="(currentPage + 1) <= num_pages()" class="page-item" @click="change_page(currentPage + 1)">
            <a class="page-link">{{ currentPage + 1 }}</a>
          </li>
          <li class="page-item" v-if="currentPage != num_pages()">
            <a class="page-link" @click="change_page(currentPage + 1)">Siguiente</a>
          </li>
          <li class="page-item" v-if="currentPage != num_pages()">
            <a class="page-link" @click="change_page(num_pages())">&raquo;</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductFotoRFCHasura,getlistofProductFotoRFCHasura } from '@/store/apibase';
import Loader from "@/components/Loader.vue";
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  components: {
    Loader,
  },
  props: {
    conFoto: Boolean
  },
  data() {
    return {
      textoLoader:
        "Por favor espere se están cargando los datos",
      tituloLoader: "Cargando Reporte",
      productos: [],
      elementsPerPage: 20,
      currentPage: 1,
      filtrado:[],
      isLoading: false,
    };
  },
  async created() {
    if (this.conFoto) {
      await this.obtenerProductosConFoto();
    } else {
      await this.obtenerProductosSinFoto();
    }
  },
  methods: {
    async aplicarfiltros() {
    if (this.filtrado === "SI") {
      await this.obtenerProductosConFoto();
    } else if (this.filtrado === "NO") {
      await this.obtenerProductosSinFoto();
    } else {
      await this.obtenerListaProductosCSFoto();
    }
  },
  async obtenerProductosSinFoto() {
    this.isLoading = true;
    try {
      const data = { Foto: 'NO' };
      const response = await axios.post(getProductFotoRFCHasura, data);
      //en desarrollo
      //this.productos = response.data.productosFoto.dbo_Prod_prov_foto;
      //en produccion
      this.productos = response.data.productosFoto.Prod_prov_foto;
      this.$swal({
                        title: "Consulta Exitosa",
                        html: `
                    <div class="mb-3">
                        
                    </div>
                `,
                        icon: "success",
                        confirmButtonText: "Aceptar"
                    });
    } catch (error) {
      console.error('ERROR', error);
    } finally {
        this.isLoading = false;
      }
  },
  async obtenerProductosConFoto() {
    this.isLoading = true;
    try {
      const data = { Foto: 'SI' };
      const response = await axios.post(getProductFotoRFCHasura, data);
      //en desarrollo
      //this.productos = response.data.productosFoto.dbo_Prod_prov_foto;
      //en produccion
      this.productos = response.data.productosFoto.Prod_prov_foto;
      this.$swal({
                        title: "Consulta Exitosa",
                        html: `
                    <div class="mb-3">
                    </div>
                `,
                        icon: "success", 
                        confirmButtonText: "Aceptar"
                    });
    } catch (error) {
      console.error('ERROR', error);
    } finally {
        this.isLoading = false;
      }
  },
  async obtenerListaProductosCSFoto() {
    this.isLoading = true;
    try {
      const response = await axios.post(getlistofProductFotoRFCHasura);
      //en desarrollo
      //this.productos = response.data.productosRFC.dbo_Prod_prov_foto;
      //en produccion
      this.productos = response.data.productosRFC.Prod_prov_foto;
      this.$swal({
                        title: "Consulta Exitosa",
                        html: `
                    <div class="mb-3">
                    </div>
                `,
                        icon: "success", 
                        confirmButtonText: "Aceptar"
                    });
    } catch (error) {
      console.error('ERROR', error);
    } finally {
        this.isLoading = false;
      }
  },
  descargarInforme() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(this.productos);
    XLSX.utils.book_append_sheet(wb, ws, 'REPORTE_PRODUCTOS');
    XLSX.writeFile(wb, 'Reporte_Productos_Superkompras.xlsx');
  },
    num_pages() {
      return Math.ceil(this.productos.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    }
  },
  computed: {
    get_rows() {
      const start = (this.currentPage - 1) * this.elementsPerPage;
      return this.productos.slice(start, start + this.elementsPerPage);
    }
  }
};
</script>

<style>
.pagination {
  cursor: pointer;
}
</style>
