<template>
        <Breadcrumbs title="Dashboard" />
        <div class="container-fluid dashboard-2">
                <div class="row starter-main">
                        <div>
                        <goalView />
                        </div>
                        <dashboard />
                </div>
        </div>
</template>
<script>

import dashboard from "@/components/dashboard.vue"
import goalView from "@/components/goalView.vue"
export default {
        components: {
                dashboard,
                goalView
        }
}
</script>