<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      errors: [],
      inputs: {
        nombre: {
          data: "",
          errorMessage: "",
        },
        correo: {
          data: "",
          errorMessage: "",
        },
        contraseña: {
          data: "",
          errorMessage: "",
        },
        repetirContraseña: {
          data: "",
          errorMessage: "",
        },
        rol: {
          data: "Selecciona un rol",
          errorMessage: "",
        },
      },
      typeContraseña: "password",
      typeRepetirContraseña: "password",
      formSubmitted: false,
      disabled: false,
      rolesConsultados: [],
    };
  },
  async mounted() {
    try {
      await this.consultarRoles();
    } catch (error) {
      this.$swal({
        icon: error.msg ? "error" : "warning",
        title: error.code ? error.code : "Error",
        text: error.msg ? error.msg : null,
      });
    }
    
  },
  computed: {
    ...mapState("autenticacion", ["roles"]),
  },
  watch: {
    roles: {
      handler(nuevosRoles, viejosRoles) {
        this.rolesConsultados = nuevosRoles;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("autenticacion", [
      "registrarUsuario",
      "actualizarUsuario",
      "consultarDatosUsuario",
      "buscarUsuarioBD",
      "guardarUsuario",
      "consultarRoles",
    ]),
    validarFormularioAlta() {
      this.disabled = true;
      this.errors = [];
      const emailRegex =
        /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;

      this.formSubmitted = true;
      let values = this.inputs;
      console.log(values);
      values.nombre.data.length <= 0
        ? (values.nombre.errorMessage = "Proporciona un nombre")
        : (values.nombre.errorMessage = "");

      console.log(values.correo.data);
      !emailRegex.test(values.correo.data)
        ? (values.correo.errorMessage = "Correo inválido")
        : (values.correo.errorMessage = "");

      console.log(values.contraseña.data);
      console.log(values.contraseña.data.length);
      values.contraseña.data.length <= 0
        ? (values.contraseña.errorMessage = "Proporciona una contraseña")
        : values.contraseña.data.length < 6
        ? (values.contraseña.errorMessage =
            "Contraseña debe ser mayor a 6 caracteres")
        : (values.contraseña.errorMessage = "");

      values.repetirContraseña.data.length <= 0
        ? (values.repetirContraseña.errorMessage = "Proporciona una contraseña")
        : values.contraseña.data != values.repetirContraseña.data
        ? (values.repetirContraseña.errorMessage =
            "La contraseñas deben coincidir")
        : (values.repetirContraseña.errorMessage = "");

      values.rol.data == "" || values.rol.data == "Selecciona un rol"
        ? (values.rol.errorMessage = "Debes seleccionar un rol")
        : (values.rol.errorMessage = "");

      for (const [key, input] of Object.entries(this.inputs)) {
        if (input.errorMessage.length) {
          this.errors.push(input.errorMessage);
        }
      }

      console.log(this.errors);
      if (this.errors.length == 0) {
        this.crearUsuario();
      } else {
        this.disabled = false;
      }
    },
    mostrarContraseña() {
      if (this.typeContraseña == "password") {
        this.typeContraseña = "text";
      } else {
        this.typeContraseña = "password";
      }
    },
    mostrarRepetirContraseña() {
      if (this.typeRepetirContraseña == "password") {
        this.typeRepetirContraseña = "text";
      } else {
        this.typeRepetirContraseña = "password";
      }
    },
    resetearFormulario() {
      this.inputs.nombre.data = "";
      this.inputs.nombre.errorMessage = "";
      this.inputs.correo.data = "";
      this.inputs.correo.errorMessage = "";
      this.inputs.contraseña.data = "";
      this.inputs.contraseña.errorMessage = "";
      this.inputs.repetirContraseña.data = "";
      this.inputs.repetirContraseña.errorMessage = "";
      this.inputs.rol.data = "Selecciona un rol";
      this.inputs.rol.errorMessage = "";
      this.formSubmitted = false;
    },
    async crearUsuario() {
      try {
        const resRegistro = await this.registrarUsuario({
          correo: this.inputs.correo.data,
          contraseña: this.inputs.contraseña.data,
          nombre: this.inputs.nombre.data,
        });
        console.log(resRegistro);
        if (resRegistro?.msg) {
          const uid = await this.consultarDatosUsuario(this.inputs.correo.data);
          console.log(uid);
          const existeUsuario = await this.buscarUsuarioBD(uid);
          console.log(existeUsuario);
          if (!existeUsuario) {
            console.log("No existe");
            await this.actualizarUsuario({
              correo: this.inputs.correo.data,
              contraseña: this.inputs.contraseña.data,
              nombre: this.inputs.nombre.data,
              uid: uid,
            });
            await this.guardarUsuario({
              correo: this.inputs.correo.data,
              contraseña: this.inputs.contraseña.data,
              nombre: this.inputs.nombre.data,
              rol: this.inputs.rol.data,
              uid: uid,
            });
          } else {
            this.$swal(
              "Oops!",
              "El usuario ya se encuentra registrado",
              "warning"
            );
            console.log("Existe!!!");
            return;
          }
        } else {
          //No existe
          console.log("No existe Auth");
          await this.guardarUsuario({
            correo: this.inputs.correo.data,
            contraseña: this.inputs.contraseña.data,
            nombre: this.inputs.nombre.data,
            rol: this.inputs.rol.data,
            uid: resRegistro.uid,
          });
        }
        this.$swal({
          title: "Usuario creado con exito!",
          icon: "success",
        }).then((result) => {
          this.$router.push(`/consultar-usuarios/${this.inputs.correo.data}`);
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.msg ? "error" : "warning",
          title: error.code ? error.code : "Error",
          text: error.msg ? error.msg : null,
        });
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
<template>
  <Breadcrumbs title="Alta de usuarios" />
  <div class="card">
    <form
      class="form theme-form needs-validation"
      @submit.prevent="validarFormularioAlta"
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label class="form-label" for="nombre">Nombre</label>
              <input
                class="form-control"
                id="nombre"
                type="text"
                placeholder="Introduce el nombre del usuario"
                v-model="inputs.nombre.data"
                v-bind:class="
                  formSubmitted
                    ? inputs.nombre.errorMessage.length
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                :disabled="disabled"
              />
              <div
                class="invalid-feedback"
                v-if="inputs.nombre.errorMessage.length"
              >
                {{ inputs.nombre.errorMessage }}
              </div>
              <div class="valid-feedback" v-else>Correcto!</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label class="form-label" for="correo">Correo electrónico</label>
              <input
                class="form-control"
                id="correo"
                type="email"
                placeholder="Introduce un correo electrónico"
                v-model="inputs.correo.data"
                v-bind:class="
                  formSubmitted
                    ? inputs.correo.errorMessage.length
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                :disabled="disabled"
              />
              <div
                class="invalid-feedback"
                v-if="inputs.correo.errorMessage.length"
              >
                {{ inputs.correo.errorMessage }}
              </div>
              <div class="valid-feedback" v-else>Correcto!</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="mb-3 inputMostrarOcultar">
              <label class="form-label" for="contraseña">Contraseña</label>

              <input
                class="form-control"
                id="contraseña"
                :type="typeContraseña"
                placeholder="Introduce una contraseña"
                v-model="inputs.contraseña.data"
                v-bind:class="
                  formSubmitted
                    ? inputs.contraseña.errorMessage.length
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                :disabled="disabled"
              />
              <div
                class="invalid-feedback"
                v-if="inputs.contraseña.errorMessage.length"
              >
                {{ inputs.contraseña.errorMessage }}
              </div>
              <div class="valid-feedback" v-else>Correcto!</div>
              <div class="mostrarOcultar">
                <span
                  :class="[typeContraseña == 'password' ? 'mostrar' : '']"
                  @click="mostrarContraseña()"
                >
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="mb-3 inputMostrarOcultar">
              <label class="form-label" for="repetirContraseña"
                >Repetir contraseña</label
              >
              <input
                class="form-control"
                id="repetirContraseña"
                :type="typeRepetirContraseña"
                placeholder="Repite la contraseña"
                v-model="inputs.repetirContraseña.data"
                v-bind:class="
                  formSubmitted
                    ? inputs.repetirContraseña.errorMessage.length
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                :disabled="disabled"
              />
              <div class="mostrarOcultar">
                <span
                  :class="[
                    typeRepetirContraseña == 'password' ? 'mostrar' : '',
                  ]"
                  @click="mostrarRepetirContraseña()"
                >
                </span>
              </div>
              <div
                class="invalid-feedback"
                v-if="inputs.repetirContraseña.errorMessage.length"
              >
                {{ inputs.repetirContraseña.errorMessage }}
              </div>
              <div class="valid-feedback" v-else>Correcto!</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label class="form-label" for="rol">Rol</label>
              <select
                class="form-select digits"
                id="rol"
                v-model="inputs.rol.data"
                v-bind:class="
                  formSubmitted
                    ? inputs.rol.errorMessage.length
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                :disabled="disabled"
              >
                <option>Selecciona un rol</option>
                <option
                  v-for="rol in rolesConsultados"
                  :key="rol.id"
                  :value="rol.id"
                >
                  {{ rol.name }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="inputs.rol.errorMessage.length"
              >
                {{ inputs.rol.errorMessage }}
              </div>
              <div class="valid-feedback" v-else>Correcto!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button class="btn btn-primary px-3 pb-2 text-white" type="submit" :disabled="disabled">
          Guardar
        </button>
        <input
          class="btn btn-light ms-2"
          type="button"
          value="Cancelar"
          :disabled="disabled"
          @click="resetearFormulario"
        />
      </div>
    </form>
  </div>
</template>
