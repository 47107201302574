<template>
  <div class="card">
    <div class="top">
      <div class="container">
        <div class="row w-100">
          <div class="col-lg-6">
            <p class="text-lg-start">
              <i class="fa-solid fa-circle-info"></i>
              Solo se aceptan imágenes en formato JPG con dimensiones mínimas de
              1024x1024 píxeles
            </p>
          </div>
          <div class="col-lg-3">
            <button type="button" class="btn btn-outline-success btn-primary" @click="uploadImages"
              :disabled="!canUpload || isLoading">
              Cargar
              <i class="fa-solid fa-upload"></i>
            </button>
          </div>
          <div class="col-lg-3">
            <button v-if="images.length > 0 || invalidFiles.length > 0" type="button"
              class="btn btn-outline-secondary btn-info" @click="clearAll">
              Limpiar
              <i class="fas fa-broom"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="drag-area" @dragover.prevent="onDragover" @dragleave.prevent="onDragleave" @drop.prevent="onDrop">
      <span v-if="!isDragging">
        Drop your images here..
        <span class="select" role="button" @click="selectFiles"> Choose </span>
        <span>or</span>
        <span class="select" role="button" @click="selectFolder">
          Choose Folder
        </span>
      </span>
      <div v-else class="select">Drop images here</div>
      <input name="file" type="file" class="file" ref="fileInput" accept=".jpg" multiple @change="onFileSelect" />
      <input name="folder" type="file" class="file" ref="folderInput" accept=".jpg" webkitdirectory
        @change="onFileSelect" />
    </div>

    <br />

    <div class="container">
      <div class="image" v-for="(image, index) in images" :key="index">
        <div class="thumbnail">
          <img :src="image.url" :class="{ uploaded: image.uploaded }" />
          <p>{{ image.name }}</p>
          <button type="button" class="btn btn-outline-danger btn-danger" @click="deleteImage(index)">
            Eliminar
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" :textoLoader="textoLoader" :tituloLoader="tituloLoader" />


    <div v-if="invalidFiles.length > 0" class="invalid-files-report">
      <h3>Imágenes no procesadas:</h3>
      <table class="display table-striped table-hover table-bordered table table-danger">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Imagen</th>
            <th>Observación</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, index) in invalidFiles" :key="index">
            <td>{{ file.name }}</td>
            <td><img :src="file.url" width="70" height="70" /></td>
            <td>{{ file.reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />

  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";
import { uploadProduct } from "@/store/apibase";
import { io } from "socket.io-client";
import { toast } from "vue3-toastify";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      textoLoader:
        "Por favor, permanezca en esta pestaña hasta que se complete la carga.",
      tituloLoader: "Cargando imágenes",
      images: [],
      invalidFiles: [],
      isDragging: false,
      uploading: false,
      isLoading: false,
      socketActual: null,
    };
  },
  mounted() {
    this.revisarConexionExistente()
  },
  computed: {
    ...mapState("autenticacion", ["usuarioActual"]),
    ...mapState("socket", ["socket"]),
    canUpload() {
      return this.images.length > 0 && !this.uploading;
    },
  },
  methods: {
    ...mapMutations("socket", ["setSocket"]),
    ...mapActions("destinatarios", ["obtenerDestinatariosImagenes"]),
    //La siguiente funcion se encarga de revisar si hay conexion existente con el socket del servidor 
    revisarConexionExistente() {
      if (!this.socket) {
        this.conectarSocket();
      } else {
        this.socketActual = this.socket;
      }
    },
    //Las siguientes funciones sirven para la interaccion de la pagina para cargar una imagen ya sea seleccionando un archivo o una carpeta e igualmente para poder arrastrarlas
    onDragover() {
      this.isDragging = true;
    },
    onDragleave() {
      this.isDragging = false;
    },
    onDrop(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    selectFiles() {
      this.$refs.fileInput.click();
    },
    selectFolder() {
      this.$refs.folderInput.click();
    },
    onFileSelect(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    //La siguiente funcion es la que se encarga de validar que las fotos cumplan con las restricciones del tipo de imagen,tamaño y nobre del archivo
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileNameWithoutExtension = file.name.toLowerCase().replace(".jpg", "");

        if (
          file.type === "image/jpeg" &&
          /^[0-9]{5,14}$/.test(fileNameWithoutExtension)
        ) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const image = new Image();
            image.onload = () => {
              if (
                image.width >= 1024 &&
                image.height >= 1024 &&
                image.width <= 2048 &&
                image.height <= 2048
              ) {
                const trimmedName =
                  fileNameWithoutExtension.replace(/^0+/, "") + ".jpg";
                this.images.push({
                  originalName: file.name,
                  name: trimmedName,
                  url: e.target.result,
                  file: file,
                  uploaded: false,
                });
              } else {
                this.invalidFiles.push({
                  name: file.name,
                  url: e.target.result,
                  reason:
                    "La imagen no cumple con las dimensiones permitidas de 1024x1024 a 2048x2048 píxeles",
                });
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.invalidFiles.push({
              name: file.name,
              url: e.target.result,
              reason: "Nombre del archivo no cumple con los requisitos",
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    //Esta funcion nos permite poder eliminar una imagen que no queramos procesar ya sea porque esta equivocada o cualquier otra razón
    deleteImage(index) {
      this.images.splice(index, 1);
    },
    clearAll() {
      this.images = [];
      this.invalidFiles = [];
    },
    //la siguiente funcion nos sirve para crear la conexion del socket para el proceso en segun plano
    conectarSocket() {
      // this.socketActual = io("http://localhost:3000", {
      //   withCredentials: true,
      //   extraHeaders: {
      //     "my-custom-header": "abcd",
      //   },
      // });

      this.socketActual = io("https://despliegueimagenessk.superkompras.com.mx", {
        withCredentials: true,
        extraHeaders: {
          "my-custom-header": "abcd",
        },
      });
      this.setSocket(this.socketActual);
      this.socketActual.on("connect", () => {
        console.log("Conectado al servidor");
        console.log(this.socketActual.id);
        this.socketActual.emit("mensaje", "Hola servidor!");
      });

      this.socketActual.on("image-upload-state", (data) => {
        console.log(data.message);

        switch (data.message) {
          case "imagenes-cargadas":
            toast.success(
              " ¡Proceso en segundo plano! Puede cerrar la pestaña si lo desea ",
              {
                hideProgressBar: true,
                autoClose: 10000,
                theme: "colored",
              }
            );
            this.tituloLoader = "Procesando imágenes";
            this.textoLoader =
              "Esto puede tardar unos minutos. Recibirá un correo cuando el proceso haya finalizado.";
            break;
          case "imagenes-procesadas":
            this.tituloLoader = "Guardando imágenes";
            this.textoLoader =
              "Esto puede tardar unos minutos. Recibirá un correo cuando el proceso haya finalizado.";
            break;
        }
      });
    },
    //Esta funcion es la que se encarga de hacer todo el proceso para poder mandar las imagenes al servidor y hacer las peticiones al endpoint de nuestro backend para poder hacer el redimencionamiento y carga de imagenes en la BD.
    async uploadImages() {
      this.tituloLoader = "Cargando imágenes";
      this.textoLoader = "Cargando imágenes. Por favor, permanezca en esta pestaña hasta que se complete la carga.";
      this.uploading = true;
      this.isLoading = true;

      const destinatarios = await this.obtenerDestinatariosImagenes();
      const destinatarioEncontrado = destinatarios.find((destinatario) => destinatario == this.usuarioActual?.email);

      if (!destinatarioEncontrado) {
        destinatarios.push(this.usuarioActual?.email);
      }

      const formData = new FormData();
      const uid = this.usuarioActual?.uid;
      console.log(uid);

      if (!uid) {
        console.error("Error: UID no está definido");
        this.uploading = false;
        this.isLoading = false;
        return;
      }

      this.images.forEach((image) => {
        const renamedFile = new File([image.file], image.name, {
          type: image.file.type,
        });
        formData.append("files", renamedFile);
      });
      formData.append("uid", uid);

      formData.append("socketId", this.socketActual.id);
      formData.append("email", destinatarios);

      const invalidFilesData = this.invalidFiles.map((image) => ({
        name: image.name,
        reason: image.reason,
      }));

      formData.append("invalidFiles", JSON.stringify(invalidFilesData));

      try {
        const response = await axios.post(uploadProduct, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.images = this.images.map((image) => ({
          ...image,
          uploaded: true,
        }));

        console.log("Éxito en la carga de imágenes");
      } catch (error) {
        console.error("Error al subir imágenes", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al subir las imágenes. Intenta nuevamente.",
        });
      } finally {
        this.uploading = false;
        this.isLoading = false;
      }
    }

  },
};
</script>

<style scoped>
/* todos son estilos para las imagenes y que nos sirven para tener una mejor vista del proceso */
.card {
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
}

.card .top {
  text-align: center;
}

.card p {
  font-weight: bold;
  color: blue;
}

.card button {
  outline: 0;
  border: 0;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background: rgb(107, 116, 241);
}

.card .drag-area {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #6e00fe;
  background: #f4f3f9;
  color: violet;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: center;
  -webkit-user-select: none;
  margin-top: 10px;
}

.card .drag-area .visible {
  font-size: 18px;
}

.card .select {
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.card .select:hover {
  opacity: 0.6;
}

.card .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card .container .image {
  margin: 0 10px 10px 0;
  width: 150px;
}

.card .container .image .thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .container .image button {
  margin-top: 5px;
}

.card .container .image img {
  width: 75px;
  height: 75px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.card .container .image p {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.card .container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.card input,
.card .drag-area .on-drop,
.card.drag-area.dragover .visible {
  display: none;
}

.card .container .image .thumbnail img.uploaded {
  border: 5px solid #68f56d;
}

.invalid-files-report {
  margin-top: 20px;
  color: red;
}
</style>
