import { createRouter, createWebHistory } from "vue-router";
import BodyView from "@/layout/BodyView.vue";
import authView from "@/layout/authView.vue";
import reenvioCorreos from "@/pages/reenvioCorreos.vue";
import indexDashbord from "@/pages/indexDashbord.vue";
import tablaProductos from "@/pages/indexTablaProductos.vue";
import tablaFotos from "@/components/tablaFotos.vue";
import indexUploadImages from "@/pages/indexUploadImages.vue";
import indexImagesGS1 from "@/pages/indexUploadImagesGS1.vue";
import loginPage from "@/pages/loginPage.vue";
import altaUsuario from "@/pages/altaUsuarios.vue";
import consultarUsuarios from "@/pages/consultarUsuarios.vue";
import store from "../store";
import errorPage2 from "../pages/error/errorPage2.vue";
import errorPage1 from "../pages/error/errorPage1.vue";

const routes = [
  {
    path: "/",
    component: BodyView,
    children: [
      {
        path: "/",
        name: "dashbord",
        component: indexDashbord,
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: "/banco-imagenes",
    component: BodyView,
    children: [
      {
        path: "/banco-imagenes",
        name: "BancoImagenes",
        component: tablaProductos,
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: "/subir-productos",
    component: BodyView,
    children: [
      {
        path: "/subir-productos",
        name: "SubirImagenes",
        component: indexUploadImages,
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: "/productos-GS1",
    component: BodyView,
    children: [
      {
        path: "/productos-GS1",
        name: "ProductosGS1",
        component: indexImagesGS1,
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: "/reenvio-correos",
    component: BodyView,
    children: [
      {
        path: "/reenvio-correos",
        name: "ReenvioCorreos",
        component: reenvioCorreos,
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    component: authView,
    children: [
      {
        path: "/login",
        name: "login",
        component: loginPage,
      },
    ],
  },
  {
    path: "/alta-usuarios",
    component: BodyView,
    children: [
      {
        path: "/alta-usuarios",
        name: "alta-usuarios",
        component: altaUsuario,
      },
    ],
    meta: {
      auth: true,
      admin: true
    },
  },
  {
    path: "/consultar-usuarios",
    component: BodyView,
    children: [
      {
        path: "/consultar-usuarios",
        name: "consultar-usuarios",
        component: consultarUsuarios,
      },
    ],
    meta: {
      auth: true,
      admin: true
    },
  },
  {
    path: "/consultar-usuarios/:correo",
    component: BodyView,
    children: [
      {
        path: "/consultar-usuarios/:correo",
        name: "consultar-usuarios-correo",
        component: consultarUsuarios,
      },
    ],
    meta: {
      auth: true,
      admin: true
    },
  },
  {
    path: "/usuario-sin-permisos",
    name: "usuario-sin-permisos",
    component: errorPage1,
  },
  {
    path: '/:pathMatch(.*)*',
    name: "pagina-no-encontrada",
    component: errorPage2,
  },
  {
    path: "/reporte-imagenes",
    component: BodyView,
    children: [
      {
        path: ":conFoto",  
        name: "TablaReporteFotos",
        component: tablaFotos,
        props: route => ({ conFoto: route.params.conFoto === 'true' }), 
      },
    ],
    meta: {
      auth: true,
    },
  }    
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to);
  if (to.meta.auth) {
    const usuario = await store.dispatch("autenticacion/sesionUsuarioActual");

    if (usuario) {
      await store.dispatch(
        "autenticacion/guardarUsuarioAutenticado",
        usuario.uid
      );
      const usuarioActual = store.getters["autenticacion/getUsuarioActual"];

      console.log(usuarioActual);

      if (usuarioActual && usuarioActual.status == "active") {
        if (to.meta.admin) {
          //Rol Admin
          if(usuarioActual.rol == "1"){
            next();
          }else{
            next("/usuario-sin-permisos");
          }
        }else {
          next();
        }

      } else {
        next("/usuario-sin-permisos");
      }
    } else {
      next("/login");
    }
  } else if (to.path == "/login") {
    const usuario = await store.dispatch("autenticacion/sesionUsuarioActual");

    if (usuario) {
      await store.dispatch(
        "autenticacion/guardarUsuarioAutenticado",
        usuario.uid
      );
      const usuarioActual = store.getters["autenticacion/getUsuarioActual"];

      if (usuarioActual) {
        next("/");
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
