<template>
    <Breadcrumbs title="Imagenes GS1"/>
     <div class="container-fluid">
             <div class="row starter-main">
                <UploadImagesGS1/>

             </div>
     </div>
  </template>
  <script>
import UploadImagesGS1 from '@/components/uploadImagesGS1.vue';
  
  export default {
         components:{
            UploadImagesGS1,  
         }
  }
  </script>