/* const uploadProduct = 'http://localhost:3000/Api/V1/Productos/upload-images3';
const updateProduct = 'http://localhost:3000/Api/V1/Productos/update-images';
const removeProduct = 'http://localhost:3000/Api/V1/Productos/';
const getProducts = 'http://localhost:3000/Api/V1/Productos/consulta-productos';
const getProductsDays = 'http://localhost:3000/Api/V1/Productos/get-products-7days';
const getTotalProducts = 'http://localhost:3000/Api/V1/Productos/get-total-products';
const getProductsfecha = 'http://localhost:3000/Api/V1/Productos/filtro-fecha';
const getProductFotoRFCHasura = 'http://localhost:3000/Api/V1/Productos/get-ProductRFCHasura';
const getlistofProductFotoRFCHasura = 'http://localhost:3000/Api/V1/Productos/get-list-of-ProductRFCHasura';
const getmultimediaContentGS1 = 'http://localhost:3000/Api/V1/Productos/GetMultimediaContent'; */

/* const uploadProduct = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/upload-images3';
const updateProduct = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/update-images';
const removeProduct = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/';
const getProducts = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/consulta-productos';
const getProductsDays = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/get-products-7days';
const getTotalProducts = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/get-total-products';
const getProductsfecha = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/filtro-fecha';
const getProductFotoRFCHasura = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/get-ProductRFCHasura';
const getlistofProductFotoRFCHasura = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/get-list-of-ProductRFCHasura';
const getmultimediaContentGS1 = 'https://pruebas.superkompras.com.mx/Api/V1/Productos/GetMultimediaContent'; */

const uploadProduct = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/upload-images3';
const updateProduct = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/update-images';
const removeProduct = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/';
const getProducts = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/consulta-productos';
const getProductsDays = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/get-products-7days';
const getTotalProducts = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/get-total-products';
const getProductsfecha = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/filtro-fecha';
const getProductFotoRFCHasura = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/get-ProductRFCHasura';
const getlistofProductFotoRFCHasura = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/get-list-of-ProductRFCHasura'; 
const getmultimediaContentGS1 = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/GetMultimediaContent';

export {uploadProduct, removeProduct, getProducts,updateProduct,getProductsDays,getTotalProducts,getProductsfecha,getProductFotoRFCHasura,getlistofProductFotoRFCHasura, getmultimediaContentGS1}